import React, { useState, useEffect } from 'react';

const UserForm = () => {
    const [users, setUsers] = useState([]);
    const [nbUsers, setNbUsers] = useState(0);
  const [username, setUsername] = useState('');
  const [ticketCount, setTicketCount] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
    const endpoint = "https://sov.imonline.ca/php/bsv.php"
    useEffect(() => {
        fetchUsers();
      }, []);
    
      useEffect(() => {
        calculateTotalTickets();
        setNbUsers(users.length)
      }, [users]);
    
      const fetchUsers = () => {
        fetch(endpoint)
          .then((response) => response.json())
          .then((data) => setUsers(data))
          .catch((error) => console.log(error));
      };
    
      const addUser = () => {
        const data = { username, ticketCount };
    
        fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              fetchUsers();
              setUsername('');
              setTicketCount(0);
            } else {
              console.log(data.message);
            }
          })
          .catch((error) => console.log(error));
      };
    
      const updateUser = (userId, newTicketCount) => {
        const data = { userId, ticketCount: newTicketCount };
    
        fetch(endpoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              fetchUsers();
            } else {
              console.log(data.message);
            }
          })
          .catch((error) => console.log(error));
      };
    
      const deleteUser = (userId) => {
        const data = { userId };
        const confirmed = window.confirm('Confirm to delete ?');
  if (confirmed) {
    
        fetch(endpoint, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              fetchUsers();
            } else {
              console.log(data.message);
            }
          })
          .catch((error) => console.log(error));


        }


      };
    
      const calculateTotalTickets = () => {
        const total = users.reduce((sum, user) => sum + parseInt(user.ticketCount), 0);
        setTotalTickets(total);
      };
    
      return (
        <div>
            <div className='inputform'>
            <h2>BSV zonepass</h2>
          <p className='my-2'>Total Tickets: {totalTickets}</p>
          <p className='my-2'>Total players: {nbUsers}</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addUser();
            }}
          >
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
            <input
              type="number"
              value={ticketCount}
              onChange={(e) => setTicketCount(parseInt(e.target.value))}
              placeholder="Ticket Count"
              required
            />
            <button className='btn btn-primary m-1' type="submit">Add User</button>
          </form>
            </div>
          
          <div>
            {users.map((user) => (
              <div key={user.id} className="row my-2 p-1 player">
                <div className="col-6 d-flex justify-content-between align-items-center">
                    <img className='mr-2' src='images/user2.jpg' width={40} height="auto"/>
                    <span className='tcount text-start w-100'> {user.username}</span>
                </div>
                <div className="col-2 d-flex justify-content-around align-items-center">
                    <img src='images/zonepass.png' width={30} height="auto"/>
                    <span className='tcount'>{user.ticketCount}</span>
                </div>
                <div className="col-2 d-flex justify-content-around align-items-center">
                        <button className='btn btn-success btn-xs p-1' 
                    onClick={() => {
                        const newTicketCount = parseInt(prompt('Enter new ticket count:', user.ticketCount));
                        if (!isNaN(newTicketCount)) {
                        updateUser(user.id, newTicketCount);
                        }
                    }}
                    >
                    Update
                    </button>
                </div>
                <div className="col-2 d-flex justify-content-around align-items-center">
                    <button className='btn btn-danger btn-xs p-1' onClick={() => deleteUser(user.id)}>Delete</button>
                </div>
                
                
                
              </div>
            ))}
          </div>
         
        </div>
      );
    };
    

export default UserForm;